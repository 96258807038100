<template>
  <a-layout id="components-layout-demo-top-side">

      <a-layout-header class="header">
      <Header :ishow="false"></Header>
      </a-layout-header>

      <a-layout-content style="margin-top: 0.42rem;background: #FFF7F7;">

          <a-layout style="flex-direction: row;justify-content: space-between;min-height:30rem;">
              <a-layout-sider width="20%"  style="background: #fff;">
                  <a-menu
                          style="width:100%;height: 100%;"
                          :default-selected-keys="defaultkey"
                          :default-open-keys="['sub1']"
                          :mode="mode"
                          :theme="theme"
                  >

                      <a-menu-item style="font-size: 0.84rem;color:#C9151E;margin-top:1.57rem;text-align: center" v-for="item in Twoclass" :key="item.ID" >
                          {{item.Name}}
                      </a-menu-item>
                  </a-menu>
              </a-layout-sider>

                  <a-table style="width: 80%;padding: 1.28rem 1.74rem 0 2.36rem;box-sizing: border-box;" size="large"  :rowKey="record => record.Num" :pagination="pagination"   @change="handleTableChange" :columns="columns" :data-source="data" bordered>
                    <template slot="action" slot-scope="text, record">
                        <span>
                            <a-progress :stroke-color="{
        from: '#108ee9',
        to: '#87d068',
      }" strokeWidth="20" :percent="record.Percent " size="small" />

                        </span>
                        </template>
                  </a-table>
             <!-- width:100%;background:#FFF7F7;padding: 42.2px 57.6px 0 45px;box-sizing: border-box;-->



          </a-layout>
      </a-layout-content>

  </a-layout>
</template>


<script>
  import Header from '../home/components/header'
  import { getItem } from "@/utils/storage";

  export default {
      name: "index",
      components:{
          Header
      },
      data(){
          return {
              mode: 'inline',
              theme: 'light',
              Twoclass:[],
              pagination: {
                  total: 0,
                  pageSize: 10,//每页中显示10条数据
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],//每页中显示的数据
                  showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
                  size:'large'
              },
    
              columns:[
                  {
                      title: '学生姓名',
                      dataIndex: 'Name',
                      width:350,
                      align:"center"
                  },
          
                  {
                        title: '学生学习进度',
                        dataIndex: 'Percent',
                        scopedSlots:{ customRender: 'action' },
                        width: 400,
                        align:"center"
                    }, 
                  {
                      title: '学生最后观看时间',
                      dataIndex: 'LastTime',
                      width:550,
                      align:"center"
                  }                                                                                                                     
              
                  ],
              data:[],
              queryParam:{
               
                  page:1,
                  size:10,
                userid:getItem('UserID')
             
              },
 
          }
      },
      created(){
        this.Twoclass=[{Name:'昌平实验小学'},{Name:'六年级3班'}];
      },
      mounted(){
        this.GetPageList();
      },
      methods:{
      
          handleTableChange(pagination){
              this.pagination.current = pagination.current;
              this.pagination.pageSize = pagination.pageSize;
              this.queryParam.page = pagination.current;
              this.queryParam.size = pagination.pageSize;
              this.GetPageList();
          },
          GetPageList(){
            
              let that=this;
              that.data=[];
              that.pagination.total=0;

              this.$axios.post("/api/Member/GetClassPercentPageList", that.queryParam).then((res) => {
                  if (res.data.Message=="SUCCESS"){
                      that.pagination.total = res.data.Data.Total;
                      that.data = res.data.Data.VideoList;
                  }
              });
          },
       
 
          play(Num,Url){
          let num=Num;
              if (!Url){
                  this.$message.error('视频暂未更新!');
              }else{

                  this.$router.push({ name:'videoplay',query:{ num: num, oneclassid:this.queryParam.oneclassid,twoclassid:this.queryParam.twoclassid,twoclassname:encodeURIComponent(this.twoclassname) }})
              }

          }
      },
      computed:{

      }
  }
</script>
<style>
  /* 适配各种屏幕尺寸 */
  @media (min-width: 1024px){
      body,html{font-size: 18px!important;}
  }
  @media (min-width: 1100px) {
      body,html{font-size: 20px!important;}
  }
  @media (min-width: 1280px) {
      body,html{font-size: 22px!important;}
  }
  @media (min-width: 1366px) {
      body,html{font-size: 24px!important;}
  }
  @media (min-width: 1440px) {
      body,html{font-size: 25px!important;}
  }
  @media (min-width: 1680px) {
      body,html{font-size: 28px!important;}
  }
  @media (min-width: 1920px) {
      body,html{font-size: 33px!important;}
  }

</style>
<style lang="less" scoped>
  #components-layout-demo-top-side .header{
      height: 2.76rem;
      background-color: #fff;
      box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);


      box-sizing: border-box;
      padding: 0.63rem 1.78rem 0.6rem 1.7rem;
  }

  ::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
      background-color:#FFF7F7;
  }
  ::v-deep .ant-menu-item::after{
      border:none;
  }
  ::v-deep .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td{
      border: none;
      height: 2.75rem;
      color: #121212;
      font-size: 0.78rem;
      background: #fff;
  }
  ::v-deep .ant-table-bordered .ant-table-tbody > tr > td{
      border: none;
      height: 2.75rem;
      color: #121212;
      font-size: 0.78rem;
      background: #fff;
  }

  ::v-deep .ant-pagination{
      font-size: 0.68rem;
      color: #121212;

  }
  ::v-deep .ant-select{
      font-size:0.68rem;
      color: #121212;

  }

  ::v-deep .ant-pagination-item a{
     color: #717272;

  }
  ::v-deep .ant-pagination-item-active a{
      color: #FFFFFF;
      background: linear-gradient(39deg, #CA1D1F, #E9363C);
  }
  ::v-deep .ant-pagination-item{
      border: 0;
      border-radius: 3px;
  }
  ::v-deep .ant-select-dropdown-menu-item{
      font-size: 0.68rem;
  }


</style>
